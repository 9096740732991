import type {ParticipantStoreType} from "@stores/generic/participant.store";

export const getParticipantDisplayName = (participant: ParticipantStoreType) => {
  if(participant.displayName) return participant.displayName;
  if(participant.name) return participant.name;
  return "cannot-obtain-displayname";
}

export const generateAvatarFromString = (text: string) => {
  return text.split(/\s+/)
    .filter(item => item.length)
    .map(item => item[0].toUpperCase())
    .join("")
}

export const getParticipantAvatar = (participant: ParticipantStoreType, isInSameParty?: boolean): string => {
  const name = isInSameParty ? getParticipantDisplayName(participant) : (participant.party ?? getParticipantDisplayName(participant));
  return generateAvatarFromString(name);
}

export const getProfileUserDisplayName = (profileUser: Backend.Models.ProfilesUser) => {
  const {user} = profileUser;
  if(user.firstname && user.lastname) return `${user["firstname"]} ${user["lastname"]}`
  if(user.firstname) return user.firstname;
  if(user.email) return user.email;
  if(user.lastname) return user.lastname;
  return "cannot-obtain-displayname"
}

export const getProfileUserAvatar = (profileUser: Backend.Models.ProfilesUser): string => {
  return generateAvatarFromString(getProfileUserDisplayName(profileUser))
}

export const ascendantNodeByTag = (tag: string, temp: HTMLElement | ParentNode | null, maxIter = 10): HTMLElement | null | ParentNode => {
  if(maxIter <= 0 || !temp) return null;
  if(temp.nodeName.toLowerCase() === tag.toLowerCase()) return temp;
  return ascendantNodeByTag(tag, temp.parentNode, maxIter--);
}

export const warnMissingElements = () => console.warn('Some element(s) that are required are missing. Insert a debugger and check the stack trace')


